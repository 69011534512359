import React from 'react'
import htmlFile from 'raw-loader!./terms-conditions.html';
import { Container } from 'semantic-ui-react';

import styled from 'styled-components';

import HomeLayout from '../components/HomeLayout';
import './tos.css';

const ToSPage = ({ className }) => {
  return (
    <HomeLayout>
      <Container>
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: htmlFile }}
        />
      </Container>
    </HomeLayout>

  )
}

export default styled(ToSPage)`
  > .c2 {
    .c7 {
      margin-left: 17.9pt;
    }
  }
  .c2, .c0 {
    text-indent: 0pt;
  }
  .c0, .c2, .c6, .c10, .c11, .c23 {
    text-indent: 0pt;
    line-height: inherit;
  }
  .lst-kix_list_13-0>li{
    line-height: 1.4;
    &:before {
      font-weight: 700;
      font-family: "Arial";
    }
  }

`;
